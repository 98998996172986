<template>
	<div class="header__tab">

		<div class="header__first-nav" :class="{ 'on-shop': route.path.includes('/shop'), 'shop-selectable':!isInShopSubPage}">
			<!--샵 내부 페이지가 아니고 샵 리스트가 하나 이상일 때 -->
			<span v-if="shopNavList.length > 1 && !isInShopSubPage" class="header__shop-button text-elice-dx-neolli-bd" @click="openShopNav">SHOP
				<span class="header__shop-button-nav-arrow" >
					{{ isShopNavOpened ? '▲' : '▼' }}
				</span>
			</span>
			<!--샵 내부 페이지가 아니고 샵 리스트가 하나일 때 -->
			<span v-else-if="shopNavList.length === 1 && !isInShopSubPage" class="header__shop-button text-elice-dx-neolli-bd" @click="goToOnlyOneShop">SHOP</span>
			<!--샵 내부 페이지가 아니고 샵 리스트가 하나도 없을 때 -->
			<span v-else-if="shopNavList.length === 0 && !isInShopSubPage" class="header__shop-button text-elice-dx-neolli-bd" @click="openShopStatusAlert">SHOP</span>
			<!--샵 내부 페이지일때-->
			<span v-else-if="isInShopSubPage" class="header__shop-button text-elice-dx-neolli-bd" @click="goToShop">SHOP</span>
		</div>
		<!--말풍선 위쪽 화살표 샵 목록이나 그 하위 페이지에서만 보입니다.-->
		<div class="header__shop-selector-wrapper" v-if="isShopNavOpened && !isInShopSubPage" @click="store.commit('setIsShopNavOpened', false)">
			<div class="header__shop-selector">
				<div class="shop-selector__triangle"></div>
				<nav class="shop-selector__link-list"><!--샵 목록이나 그 하위 페이지에서만 보입니다.-->
					<router-link v-for="item in shopNavList" :key="item" :to="`/shop?game=${item.urlGameName}`" @click="initNavsAndsessionStorage">
						<img :src="item.gameImg" :alt="`${item.gameName}`">
						<span class="text-elice-dx-neolli-m">{{ item.gameName }}</span>
					</router-link>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, watch, computed} from 'vue'
	import { useStore } from 'vuex'
	import { useRoute, useRouter } from 'vue-router'
	const store = useStore()
	const route = useRoute()
	const router = useRouter()
	const shopNavList = computed(()=>store.getters.getShopNavList)
	const isShopNavListLoadingFinished = computed(() => store.getters.getIsShopNavListLoadingFinished) 
	const isInShopSubPage = computed(() => store.getters.getIsInShopSubPage) // 샵 또는 샵 하위 페이지에 있는지 체크
	const shopName = computed(()=>store.getters['shopPage/getGameName'])//각 게임샵별 하위 페이지에 있을 때 소속 샵 이름 체크
	const isShopNavOpened = computed(() => store.getters.getIsShopNavOpened)
	const toggleGlobalNavigation = (boolean) => {
		store.commit('setIsShopNavOpened', false)
		store.commit('toggleGlobalNavigation', boolean)
	}
	const initNavsAndsessionStorage = () =>{ // 글로벌 네비게이션, 입졈 샵 네비게이션 초기화
		sessionStorage.clear() //샵이 변경될 때는 모든 기록을 초기화합니다.
		store.commit('toggleGlobalNavigation', false)
		store.commit('setIsShopNavOpened', false)
	}
	const setIsInShopSubPage = () =>{
		store.commit('setIsInShopSubPage', route.name === 'ShopPage' || route.name === 'ShopDetail' || route.name === 'ShopPurchase' || route.name === 'ShopComplete')
	}
	const goToShop = () =>{
		toggleGlobalNavigation(false)
		if(route.name === 'ShopPage'){
			return 
		}
		router.push(`/shop?game=${shopName.value}`)
	}
	const openShopNav = () =>{
		if(!isShopNavListLoadingFinished.value){
			return
		}
		toggleGlobalNavigation(false)
		store.commit('setIsShopNavOpened', true)
	}
	const goToOnlyOneShop = () =>{
		if(!isShopNavListLoadingFinished.value){
			return
		}
		toggleGlobalNavigation(false)
		const gameName = shopNavList.value[0].urlGameName || sessionStorage.getItem('gameName')
		router.push(`/shop?game=${gameName}`)
	}
	const openShopStatusAlert = () =>{
		if(!isShopNavListLoadingFinished.value){
			return
		}
		toggleGlobalNavigation(false)
		store.dispatch('resultCodeProcessor/setSelectedPopupByPayload', {
			text: '현재 모든 상점이 서비스 점검 중이거나 준비 중입니다.'
		})
	}
	const getShopNavList = async () => {
		if(isInShopSubPage.value){ // 샵 리스트 호출: 샵 내부 페이지인 경우 호출하지 않습니다. 
			return 
		}
		await store.dispatch('getShopNavList')
	}
	watch(route, async () => {
		setIsInShopSubPage()
		await getShopNavList()
	})

	onMounted(async () => {
		setIsInShopSubPage()
		await getShopNavList()
	})
</script>

<style scoped>
	.header__tab {
		display: flex;
		gap: 36px;
		margin: 8px 0 0 0;
		height: 24px;
	}
	.header__first-nav {
		height: 24px;
		position: relative;
		text-align:left;
	}
	.header__first-nav .header__shop-button{
		font-size: 18px;
		line-height: 1.56;
		letter-spacing: -0.9px;
		line-height: 24px;
		color: #6035f1;
	}
	.header__first-nav .header__shop-button:hover{
		cursor:pointer;
	}
	.header__first-nav .header__shop-button .header__shop-button-nav-arrow{
		color: #6035f1;
		font-size: 12px;
		margin: 0 0 0 2px;
	}

	.header__first-nav.on-shop .header__shop-button{
		color: #6035f1;
		border-bottom: 2px solid #6035f1;
	}
	.header__shop-selector-wrapper{
		position: fixed;
		left:0;
		top:0; 
		width:100%;
		height:100%;
	}
	.header__shop-selector{
		position: absolute;
		left: 50%;
		top: 70px;
		transform: translateX(-50%);
		background: #edf5fe;
		padding: 16px;
		width: 220px;
		border-radius: 12px;
		border: 1px solid #1473f3;
	}
	.shop-selector__triangle{
		position: absolute;
		width: 8px;
		height: 8px;
		left: 50%;
		top:-2px;
		border:1px solid #1473f3; 
		border-right:none;
		border-bottom:none;
		background: #edf5fe;
		transform:rotateY(45deg) rotateZ(45deg) translateX(-50%);
		z-index:1;
	}
	.shop-selector__link-list{
		display:flex;
		flex-direction:column;
		gap:8px;
	}
	.shop-selector__link-list a{
		display:block;
		text-align: left;
		line-height:34px;
		height:36px;
	}
	.shop-selector__link-list a img{
		width:36px;
		height:36px;
		border-radius:4px;
		margin-right:8px;
	}
	.shop-selector__link-list a span{
		text-align: left;
		color:#888888;
		letter-spacing: -0.7px;
		opacity:0.5;
		font-size:14px;
		vertical-align: middle;
		text-overflow:ellipsis;
		white-space: nowrap;
		overflow: hidden;
		position: relative;
	}
	.shop-selector__link-list a:hover span{
		color:#196ef3;
		opacity:1;
		font-weight:600;
	}
	.shop-selector__link-list a:hover span:after{
		content:'v';
		display:inline-block; 
		width:16px;
		height:14px;
		color:#fff;
		background:#6035f1;
		line-height:14px;
		text-align: center;
		font-size:10px;
		border-radius:4px 0 4px 0;
		vertical-align: middle;
		margin: -4px 0 0 4px;
	}
	@media screen and (min-width:375px) {
		.header__shop-selector{
			left:144px;
			transform: translateX(0%);
		}
		.shop-selector__triangle{
			left:20px;
		}
	}
	@media screen and (min-width:430px) {
		.shop-selector__triangle{
			left:20px;
		}
	}
	@media screen and (min-width:1200px) {
		.header__tab {
			margin: 26px 0 0 0;
		}
		.header__shop-button.pc:hover{
			cursor:pointer;
		}
		.header__shop-selector{
			top: 94px;
			left: 50%;
			transform: translate(calc(-382px));
			width: auto;
			padding: 18px 32px;
		}
		.shop-selector__triangle{
			width:10px;
			height:10px;
			left:42px;
		}
		.shop-selector__link-list{
			width:auto;
			flex-direction:row;
			gap:30px;
		}
		.shop-selector__link-list a{
			display:block;
			width:116px;
			height:auto;
			text-align: center;
		}
		.shop-selector__link-list a img{
			display:block;
			width:80px;
			height:80px;
			border-radius:8px;
			margin:0 auto;
		}
		.shop-selector__link-list a span{
			text-align: center;
			border:1px solid #ecf5fe;
			margin:10px auto 0;
			border-radius:4px;
			height:26px; 
			line-height:26px;
			padding:4px 12px;
		}
		.shop-selector__link-list a:hover span{
			background:#fff;
			border:1px solid #206af3;
		}
		.shop-selector__link-list a:hover span:after{
			right:-8px; 
			top:-8px;
			display:block; 
			margin:0;
			position: absolute;
		}
	}
</style>