import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue3Lottie from 'vue3-lottie'
import { createMetaManager } from 'vue-meta'
import axios from 'axios'
import './assets/style.css'
const apiURL = process.env.VUE_APP_API 
const app = createApp(App)

// 클라이언트단 에러 추적 

function removeQuotesAndKorean(str) {
    return str.replace(/['"가-힣]/g, '');
}
const reportErrorToServer = async (error, instance, info) => {
	const log = {
		message: error.message || "Unknown error",
		stack: error.stack || "Stack trace not available",
		info: info || "Unknown context",
		component: instance?.type?.name || `Unknown Component (route: ${window.location.pathname})`,
		route: window.location.pathname || "Unknown route",
		previousRoute: window.previousRoute || "No previous route",
		url: window.location.href || "Unknown URL",
		userAgent: navigator.userAgent || "Unknown userAgent",
		timestamp: new Date().toLocaleString("ko-KR", { timeZone: "Asia/Seoul" }),
	}
	console.log(log)
	Object.keys(log).forEach(key => {
		log[key] = removeQuotesAndKorean(log[key]);
	});
	try {
		await axios.post(`${apiURL}/api/v1/log`, {
			logContents: JSON.stringify(log),
			logFlag: "E"
		});
	} 
	catch (err) {
		console.error("Error reporting failed", err);
	}
};
app.config.errorHandler = (error, instance, info) => {
	console.error("Vue Error Captured:", error);
	reportErrorToServer(error, instance, info);
};

app.use(store).use(router).use(Vue3Lottie).use(createMetaManager())
await router.isReady()
app.mount('#app')

