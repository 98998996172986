<template>
	<!--header__join-button, header__login-button 클래스는 구글 태그매니저와 연동되어 있으므로 변경 시 유의하세요.-->
	<div>
		<div class="global-navigation__cover" :class="{ active: isGlobalNavActived }" @click="store.commit('toggleGlobalNavigation', false)"></div>
		<div class="global-navigation" :class="{ active: isGlobalNavActived }" ref="globalNavDom">
			<div class="global-navigation__wrapper">
				<!-- 유저 정보 -->
				<div class="global-navigation__account-status">
					<div
						class="global-navigation__logged-in"
						v-if="loginStatus && loginStatus.isLogin"
					>
						<img
							@click="pushToUserInfo(false)"
							class="global-navigation__logged-in--ic-profile on-cursor"
							src="@/assets/img/ic_profiles_ss@2x.png"
							alt="profile"
						/>
						<span
							class="global-navigation__user-name"
							@click="pushToUserInfo(false)"
						>
							{{ `${loginStatus.memname}`}}<span class="text-default">님</span>
						</span>
						<button
							class="global-navigation__logout-button"
							@click="logout"
						>
							로그아웃
						</button>
					</div>
					<div class="global-navigation__logged-out" v-else>
						<span
							class="global-navigation__join header__join-button on-cursor"
							@click="goToCheckAdult(false)"
							>회원가입</span
						>
						<button
							class="global-navigation__login-button header__login-button"
							@click="login"
						>
							로그인
						</button>
					</div>
				</div>
				<!-- 마일리지 및 보유 쿠폰 -->
				<div
					class="global-navigation__user-mileage--wrapper"
					v-if="loginStatus && loginStatus.isLogin"
				>
					<ul class="global-navigation__user-mileage">
						<li
							class="global-navigation__user-mileage--status"
							@click="pushToMyPage('/mypage/benefit')"
						>
							<img
								src="@/assets/img/icon_mileage_ss@2x.png"
								alt="icon-mileage"
							/>
							<div
								class="global-navigation__user-mileage--status--direction-col"
							>
								<span class="text-default">마일리지</span>
								<strong class="text-number-bd">
									{{loginStatus.mempoint.toLocaleString()}} M
								</strong>
							</div>
						</li>
						<li
							class="global-navigation__user-mileage--status on-cursor"
							@click="pushToMyPage('/mypage/holdingCode')"
						>
							<img
								src="@/assets/img/icon_code_ss@2x.png"
								alt="icon-coupon"
							/>
							<div
								class="global-navigation__user-mileage--status--direction-col"
							>
								<span class="text-default"
									>미확인 보유코드</span
								>
								<strong class="text-number-bd"
									>{{ loginStatus.codecnt
									}}<span class="text-bold">매</span>
								</strong>
							</div>
						</li>
					</ul>
				</div>
				<!-- 마이페이지 부분 -->
				<div
					class="global-navigation__category"
					v-if="loginStatus && loginStatus.isLogin"
				>
					<h3 class="text-elice-dx-neolli-bd">마이페이지</h3>
					<ul class="global-navigation__category--container">
						<li
							v-for="(nav, i) in navList"
							:key="i"
							class="global-navigation__category--card"
							@click="pushToMyPage(nav.path)"
						>
							<img
								:src="
									require(`@/assets/img/ic_my_0${
										i + 1
									}@2x.png`)
								"
								alt="구매내역아이콘"
							/>
							<h5 class="text-medium">{{ nav.name }}</h5>
						</li>
					</ul>

					<!-- <ul></ul> -->
				</div>
				<div class="global-navigation__category" v-if="isInShopSubPage">
					<h3 class="global-navigation__category--shop text-elice-dx-neolli-bd">
						<span class="text-elice-dx-neolli-bd" @click="goToShop">SHOP</span>
					</h3>
				</div>
				<div class="global-navigation__category" v-else-if="shopNavList.length === 0 && !isInShopSubPage">
					<h3 class="global-navigation__category--shop text-elice-dx-neolli-bd">
						<span class="text-elice-dx-neolli-bd" @click="openShopStatusAlert">SHOP</span>
					</h3>
				</div>
				<div class="global-navigation__category" v-else-if="shopNavList.length === 1 && !isInShopSubPage">
					<h3 class="global-navigation__category--shop text-elice-dx-neolli-bd">
						<span class="text-elice-dx-neolli-bd" @click="goToOnlyOneShop">SHOP</span>
					</h3>
				</div>
				<div class="global-navigation__category" v-else-if="shopNavList.length > 1 && !isInShopSubPage">
					<h3 class="global-navigation__category--shop text-elice-dx-neolli-bd">
						<span class="text-elice-dx-neolli-bd">SHOP</span>
						<div class="global-navigation__shop-selector">
							<nav class="shop-selector__link-list">
								<router-link v-for="item in shopNavList" :key="item" :to="`/shop?game=${item.urlGameName}`" @click="initNavsAndsessionStorage">
									<img :src="item.gameImg" :alt="`${item.gameName}`">
									<span class="text-elice-dx-neolli-m">{{ item.gameName }}</span>
								</router-link>
							</nav>
						</div>
					</h3>
				</div>
				<!-- 차후 라우터로 대체 -->
				<nav class="global-navigation__navigation">
					<ul>
						<li class="on-cursor" @click="goToHome(false)">
							서비스소개
						</li>
						<li class="on-cursor" @click="goToTerms(1)">
							이용 약관
						</li>
						<li class="on-cursor" @click="goToTerms(2)">
							개인정보처리방침
						</li>
						<!--에러 로그 수집 테스트용 -->
						<li v-if="loginStatus.memname === '정성윤'" @click="triggerIntendedError" style="color:#fff;">test</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {gtmCustomEvent, triggerIntendedError, saveReturningPageAfterLogin} from '@/util/global'
const store = useStore();
const route = useRoute();
const router = useRouter();
const loginStatus = ref(computed(() => store.getters.getLoginStatus));
const isGlobalNavActived = ref(
	computed(() => store.getters.getIsGlobalNavActived)
);
const shopNavList = computed(()=>store.getters.getShopNavList)
const shopName = computed(()=>store.getters['shopPage/getGameName'])//각 게임샵별 하위 페이지에 있을 때 소속 샵 이름 체크
const isInShopSubPage = computed(() => store.getters.getIsInShopSubPage) // 샵 또는 샵 하위 페이지에 있는지 체크
const globalNavDom = ref(null)
const navList = ref([
	{ name: "구매내역", path: "/mypage/history" },
	{ name: "보유코드", path: "/mypage/holdingCode" },
	{ name: "혜택관리", path: "/mypage/benefit" },
	{ name: "문의내역", path: "/mypage/inquiry?methodId=4" },
]);

const initNavsAndsessionStorage = () =>{ // 글로벌 네비게이션, 입졈 샵 네비게이션 초기화
	sessionStorage.clear() //샵이 변경될 때는 모든 기록을 초기화합니다.
	store.commit('toggleGlobalNavigation', false)
	store.commit('setIsShopNavOpened', false)
}
const login = async () => {
	gtmCustomEvent('loginButtonClick')
	store.commit("toggleGlobalNavigation", false);
	if(route.name !== "AccountLogin"){ // 로그인 화면이 아닐때만 저장합니다.
		saveReturningPageAfterLogin(route)
		sessionStorage.setItem('nextPageForNotJoined', 'AccountCheckAdult')
	}
	await router.push({ name: "AccountLogin" });
};

const pushToMyPage = (path) => {
	router.push(path);
	store.commit("toggleGlobalNavigation", false);
};

const logout = () => {
	window.open("/logout", "_self");
};
const goToShop = () =>{
	initNavsAndsessionStorage()
	if(route.name === 'ShopPage'){
		return 
	}
	router.push(`/shop?game=${shopName.value}`)
}
const goToOnlyOneShop = () =>{
	initNavsAndsessionStorage()
	router.push(`/shop?game=${shopNavList.value[0].urlGameName}`)
}
const openShopStatusAlert = () =>{
	store.commit("toggleGlobalNavigation", false);
	store.dispatch('resultCodeProcessor/setSelectedPopupByPayload', {
		text: '현재 모든 상점이 서비스 점검 중이거나 준비 중입니다.'
	})
}
const goToTerms = (value) => {
	router.push({ name: "Terms", query: { type: value } });
	store.commit("toggleGlobalNavigation", false);
};

const goToCheckAdult = (boolean) => {
	router.push("/account/join/checkAdult");
	store.commit("toggleGlobalNavigation", boolean);
};

const goToHome = (boolean) => {
	router.push({ name: "Home" });
	store.commit("toggleGlobalNavigation", boolean);
};

const pushToUserInfo = (boolean) => {
	router.push({ name: "Modify" });
	store.commit("toggleGlobalNavigation", boolean);
};

// 닫았다가 열어도 항상 최상단을 유지하도록 합니다. watch에서 동작합니다.
const globalNavScrollTop = () =>{
	globalNavDom.value.scrollTo(0, 0)
} 
watch(isGlobalNavActived, (value)=>{
	if(value){
		globalNavScrollTop()
	}
})
onMounted(() => {
	store.commit("toggleGlobalNavigation", false);
});
</script>

<style scoped>
.global-navigation__cover {
	width: 100%;
	height: calc(100% - 80px);
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	left: 0;
	top: 80px;
	z-index: 2;
	display: none;
}

.global-navigation__cover.active {
	display: block;
}

.global-navigation {
	top: 80px;
	right: -100%;
	height: calc(100% - 80px);
	width: 100%;
	max-width: 480px;
	padding: 15px 3% 222px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
	background-color: #fff;
	position: fixed;
	z-index: 3;
	transition: 0.3s ease-out;
	overflow-y: scroll;
}

.global-navigation::-webkit-scrollbar {
	display: none;
}

.global-navigation.active {
	right: 0;
	transition: 0.3s ease-out;
}

.global-navigation__logged-in {
	display: flex;
	align-items: center;
	padding-bottom: 15px;
}

.global-navigation__account-status {
	border-bottom: 1px solid #d7d8d8;
}

.global-navigation__logged-in--ic-profile {
	width: 30px;
	height: 30px;
	margin-right: 5px;
}

.global-navigation__logged-in button {
	margin: 0;
	width: auto;
	display: inline-block;
}

.global-navigation__user-name,
.global-navigation__join {
	line-height: 26px;
	font-size: 18px;
	letter-spacing: -0.9px;
	font-weight: bold;
}
.global-navigation__user-name:hover,
.global-navigation__join:hover{
	cursor: pointer;
}
.global-navigation__user-name > span {
	font-weight: normal;
}

.global-navigation__user-name:after,
.global-navigation__join:after {
	content: "|";
	margin: 0px 12px;
	font-size: 18px;
}

.global-navigation__logged-out {
	padding-bottom: 15px;
}

.global-navigation__logged-out .global-navigation__login-button,
.global-navigation__logged-in .global-navigation__logout-button {
	display: inline-block;
	width: 114px;
	height: 38px;
	font-size: 18px;
	font-weight: bold;
	line-height: 36px;
	letter-spacing: -0.9px;
	text-align: center;
	border: solid 1px #17191d;
	border-radius: 19.5px;
}

/* 유저정보 */

/* 마일리지 및 보유 코드 */
.global-navigation__user-mileage {
	width: 100%;
	background-color: #f8f9fa;
	position: relative;
	border-radius: 8px;
	margin: 14px 0 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.global-navigation__user-mileage--status {
	display: flex;
	gap: 6px;
	align-items: center;
	flex: 1;
	max-width: 200px;
}

.global-navigation__user-mileage--status--direction-col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.global-navigation__user-mileage--status img {
	margin: 0px 7px;
	display: inline-block;
	width: 30px;
	height: 30px;
}

.global-navigation__user-mileage--status span {
	display: inline-block;
	font-size: 14px;
	line-height: 1.63;
	letter-spacing: -0.8px;
	color: #30384d;
}

.global-navigation__user--display-name.text-bold {
	font-size: 20px;
}

.global-navigation__user-mileage--wrapper {
	border-bottom: 1px solid #d7d8d8;
	padding-bottom: 14px;
}

.global-navigation__user-mileage--status:nth-child(1) {
	border-right: 1px solid #fff;
}

.global-navigation__category {
	border-bottom: 1px solid #d7d8d8;
}

.global-navigation__category h3 {
	padding: 24px 0 0;
}

.global-navigation__category h3,
.global-navigation__category h3 span {
	color: #6035f1;
	letter-spacing: -0.9px;
	font-size: 18px;
}

.global-navigation__category h3.global-navigation__category--shop {
	padding-bottom: 24px;
}

.global-navigation__shop-selector{
	margin-top:24px;
}
.global-navigation__category--container {
	display: flex;
	justify-content: space-between;
	gap: 3%;
	margin: 10px 0 0;
	padding-bottom: 38px;
	max-width: 400px;
}

.global-navigation__category--card {
	width: 80px;
	height: 80px;
	border-radius: 8px;
	background-color: #eef5fe;
	padding: 12px 0 6px;
}

.global-navigation__category--card img {
	object-fit: contain;
	max-width: 36px;
	display: block;
	margin: 0 auto;
	margin-bottom: 2px;
}

.global-navigation__category--card h5 {
	text-align: center;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: -0.8px;
	font-weight: 500;
	font-style: normal;
}

.global-navigation__navigation {
	padding: 24px 0 0;
}

.global-navigation__navigation ul li {
	font-size: 16px;
	margin-bottom: 24px;
	letter-spacing: -0.8px;
}
.shop-selector__link-list{
	display:flex;
	flex-direction:column;
	gap:8px;
}
.shop-selector__link-list a{
	display:block;
	text-align: left;
	line-height:34px;
	height:36px;
}
.shop-selector__link-list a img{
	width:36px;
	height:36px;
	border-radius:4px;
	margin-right:8px;
}
.shop-selector__link-list a span{
	text-align: left;
	color:#888888;
	letter-spacing: -0.7px;
	opacity:0.5;
	font-size:14px;
	vertical-align: middle;
	text-overflow:ellipsis;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
}
.shop-selector__link-list a:hover span{
	color:#196ef3;
	opacity:1;
	font-weight:600;
}
.shop-selector__link-list a:hover span:after{
	content:'v';
	display:inline-block; 
	width:16px;
	height:14px;
	color:#fff;
	background:#6035f1;
	line-height:14px;
	text-align: center;
	font-size:10px;
	border-radius:4px 0 4px 0;
	vertical-align: middle;
	margin: -4px 0 0 4px;
}
@media screen and (min-width: 375px) {
	.global-navigation__user-mileage {
		height: 62px;
		flex-direction: row;
		align-items: center;
	}

	.global-navigation {
		padding: 15px 5% 222px 5%;
	}
}

@media screen and (min-width: 400px) {
	.global-navigation__category--card {
		padding: 3% 2% 1% 2%;
	}

	.global-navigation__user-mileage--status span {
		font-size: 16px;
	}

	.global-navigation__user-mileage--status img {
		margin: 0px 1%;
	}
}
@media screen and (min-width: 1200px) {
	.global-navigation__cover.active {
		display: none;
	}

	.global-navigation.active {
		display: none;
	}
	.shop-selector__link-list{
		width:auto;
		flex-direction:row;
		gap:30px;
	}
	.shop-selector__link-list a{
		display:block;
		width:116px;
		height:auto;
		text-align: center;
	}
	.shop-selector__link-list a img{
		display:block;
		width:80px;
		height:80px;
		border-radius:8px;
		margin:0 auto;
	}
	.shop-selector__link-list a span{
		text-align: center;
		border:1px solid #ecf5fe;
		margin:10px auto 0;
		border-radius:4px;
		height:26px; 
		line-height:26px;
		padding:4px 12px;
	}
	.shop-selector__link-list a:hover span{
		background:#fff;
		border:1px solid #206af3;
	}
	.shop-selector__link-list a:hover span:after{
		right:-8px; 
		top:-8px;
		display:block; 
		margin:0;
		position: absolute;
	}
}
</style>