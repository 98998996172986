<template>
	
	<div v-if="shopRegInspectData">
		<!--정기점검 상태-->
		<shopRegInspecting/>
		<appFooterUnderInspecting/>
	</div>
	<div v-else>
		<!--정상운영 상태-->
		<appHeader/>
		<router-view :key="$route.fullPath"></router-view>
		<appFooter/>
		<layerPopup />
		<applicationPopup />
		<layerPopupOnBlackList />
	</div>
</template>

<script setup>
import { watch, onMounted, computed} from "vue";
import { useStore } from "vuex";
import { useRoute} from "vue-router";
import appHeader from "@/components/common/appHeader.vue";
import appFooter from "@/components/common/appFooter.vue";
import layerPopup from "@/components/common/layerPopup.vue";
import layerPopupOnBlackList from "@/components/common/layerPopupOnBlackList.vue";
import applicationPopup from "@/components/common/applicationPopup.vue";
import shopRegInspecting from "@/components/regInspecting.vue"
import appFooterUnderInspecting from "@/components/common/appFooterUnderInspecting.vue"
const store = useStore()
const route = useRoute()

// 정기점검(전체 샵 점검) 인지 아닌지 판단
const shopRegInspectData = computed(()=> store.getters.getShopRegInspectData) 

// 팝업 오픈 후 뒤로가기시 전체 잠기는 버그 수정
watch(route, () => {
	store.commit("startDataLoading");
	//팝업 닫기
	store.commit("resultCodeProcessor/setActived", false);
	store.commit("inquiry/toggleIsInquiryPopupActived", false); 
	store.commit('toggleApplicationPopup', false)
	//스크롤 이슈 방지
	document.body.style.overflow = "auto";
	document.body.style.position = "relative";
});

onMounted(() => {
	//구글 스크립트 태그 삽입
	const createdGTAGScript = document.createElement("script");
	const createdDataLayerScript = document.createElement("script");
	const createdWDSLIScript = document.createElement("script");
	const createdNoscript = document.createElement("noscript");
	const createdIFrame = document.createElement("iframe");
	const htmlTag = document.querySelector("html");
	createdGTAGScript.setAttribute(
		"src",
		"https://www.googletagmanager.com/gtag/js?id=G-7HQJKGNS05"
	);
	createdDataLayerScript.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', 'G-7HQJKGNS05');
	`;
	createdWDSLIScript.innerHTML = `
		<!-- Google Tag Manager -->
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-T2RV3QVR');
		<!-- End Google Tag Manager -->
	`;
	createdIFrame.src = "https://www.googletagmanager.com/ns.html?id=GTM-T2RV3QVR";
	createdIFrame.height = "0";
	createdIFrame.width = "0";
	createdIFrame.style.display = "none";
	createdIFrame.style.visibility = "hidden";
	createdNoscript.appendChild(createdIFrame);
	document.head.appendChild(createdGTAGScript);
	document.head.appendChild(createdDataLayerScript);
	document.head.appendChild(createdWDSLIScript);
	htmlTag.appendChild(createdNoscript);
});
</script>
<style></style>