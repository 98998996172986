import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import accountPageRoutes from "./pages/accountPageRoutes";
import shopPageRoutes from "./pages/shopPageRoutes";
import myPageRoutes from "./pages/myPageRoutes";

const routes = [
    {
        // 서비스 소개
        path: "/",
        name: "Home",
        component: () => import("@/components/serviceIntroducePage"), // import("@/components/homePage"),
        meta: {
            title: "GameBridge | 게임브릿지",
        },
    },
    {
        // 솔루션 소개
        path: "/solution",
        name: "Solution",
        component: () => import("@/components/solutionIntroducePage"), // import("@/components/homePage"),
        meta: {
            title: "비즈니스소개",
        },
    },
    ...accountPageRoutes, // 계정
    ...shopPageRoutes, //샵
    ...myPageRoutes, // 마이페이지
    {
        path: "/terms",
        name: "Terms",
        component: () => import("@/components/termsPage"),
    },
	// {
	// 	path:'/errorPage/page',
	// 	name:'ErrorPage',
	// 	component: () => import("@/components/errorPage"),
	// },
    // beforeEach 실행 전 route유효성을 검사하고, 유효하지 않으면 Home으로 보냅니다.
    // { path: "/:pathMatch(.*)*", redirect: { name: "Home" }},
];

// 상품목록, 구매내역, 문의내역의 경우 스크롤 위치 저장
const router = new createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior(from, to, savedPosition) {
        const saveScrollPos = () => {
            store.commit("setSavedScrollPosition", {
                conditional: true,
                ...savedPosition,
            });
        };

        if (
            savedPosition &&
            from.name === "ShopPage" &&
            to.name === "ShopDetail"
        ) {
            saveScrollPos();
        } else if (
            savedPosition &&
            from.name === "History" &&
            to.name === "HistoryDetail"
        ) {
            saveScrollPos();
        } else if (
            savedPosition &&
            from.name === "Inquiry" &&
            to.name === "InquiryDetail"
        ) {
            saveScrollPos();
        } else {
            store.commit("setSavedScrollPosition", {
                conditional: false,
                left: 0,
                top: 0,
            });
            return { left: 0, top: 0 };
        }
    },
});

//유효한 페이지 path Array
const validPages = router.getRoutes().map(route => route.path);

//라우터 유효성 검사 
const checkRouteValidity = (to, next) =>{
    if(!validPages.includes(to.path)){
        // console.log('유효하지 않은 페이지 접근 시도', to.path)
        next('/')
    }
    else{
        // console.log('유효한 페이지')
        next()
    }
} 

//

router.beforeEach(async (to, from, next) => {
    //에러 로그 수집을 위한 이전  route 저장 
    window.previousRoute = from.fullPath || null;
    
    // 인증페이지에서는 정기점검 상태 유무를 체크하지 않습니다. 본인인증 프로세스와 로직이 겹치는 현상을 방지합니다.
    if(to.path === '/account/join/certify'){
        return next()
    }
    // 정기점검(전체 샵 점검) 인지 아닌지 확인
    await store.dispatch('getShopRegInspectingData')
    //사용자가 이동하려는 페이지가 유효한지 확인
    checkRouteValidity(to, next)
})
router.afterEach((to) => {
    //타이틀 변경
    const title = to.meta.title === undefined ? "GameBridge | 게임브릿지" : to.meta.title;
    document.title = title;
});

export default router;
