import store from "@/store";
const clearsessionStorageExceptSpecificKey = (keyToExclude) =>{
	const sessionStorageKeys = Object.keys(sessionStorage);
	// 각 키를 확인하면서 특정 값인 경우를 제외하고 제거합니다.
	sessionStorageKeys.forEach(item => {
		if (item !== keyToExclude) {
			sessionStorage.removeItem(item);
		}
	});
}
export default [
	// { // 입점 게임 수가 늘어날 경우 재사용 고려 2025.01.17
    //     // 서비스 소개
    //     path: "/shopMain",
    //     name: "ShopMain",
    //     component: () => import("@/components/shopMain"), // import("@/components/homePage"),
    //     meta: {
    //         title: "GameBridge | 게임브릿지",
    //     },
	// 	async beforeEnter(to, from, next){
	// 		//next("/shop?game=lostcity"); //무조건 로스트시티 샵으로 이동하기
	// 		// gameCount가 0이면 샵으로 이동되지 않는 부분을 주의해야 합니다.
	// 		const gameCount = await store.dispatch('shopMain/getGameCount')
	// 		if(gameCount > 1 ){
	// 			next()
	// 		}
	// 		else if(gameCount === 1) {
	// 			next("/shop?game=lostcity");
	// 		}
	// 	}
    // },
    {
        path: "/shop",
        name: "ShopPage",
        component: () => import("@/components/shop/shopPage"),
		beforeEnter:(to, from, next)=>{
			if(from.name === 'ShopComplete'){
				sessionStorage.clear()
			}
			else{
				clearsessionStorageExceptSpecificKey('menuid')
				store.commit('shopPage/setItemQuantity', 1)
			}
			next()
		}
    },
    {
        path: "/shop/detail",
        name: "ShopDetail",
        component: () => import("@/components/shop/shopDetail"),
		beforeEnter:(to, from, next)=>{
			//url 정보 저장: 정체불명의 NaN이 나오는 현상이 있어 검사합니다.
			store.dispatch('shopPage/getURLs', {previousURL:from.fullPath, currentURL:to.fullPath})
			if(from.name === undefined || from.name === 'ShopPage' || from.name === 'AccountLogin'){
				next()
			}
            else if(from.name === 'ShopComplete' ){
                sessionStorage.clear()
                next()
            }
			else{
				clearsessionStorageExceptSpecificKey('menuid')
				next()
			}
		}
    },
    {
        path: "/shop/purchase",
        name: "ShopPurchase",
        component: () => import("@/components/shop/shopPurchase"),
        beforeEnter: async (to, from, next) => {
			if(!sessionStorage.getItem('itemTitle')){
				next("/");
			}
            else if (from.name === "ShopDetail" || from.name === 'ShopDrop' || from.name === 'AccountLogin') {
				next();
            } 
			else if(from.name === undefined){ //새로고침 시 
				const accesskey = sessionStorage.getItem('itemAccessKey')
				const param = {
					gameid: parseInt(sessionStorage.getItem('gameid')),
					itemid: parseInt(sessionStorage.getItem('itemid')),
					itemcnt: parseInt(sessionStorage.getItem('quantity')),
				}
				if(accesskey){
					param.accesskey = accesskey
				}
				await store.dispatch("shopPage/getOrderRequestResponse", param)
				next();
			}
			else {
				next("/");
            }
        },
    },
    {
        path: "/shop/payloading",
        name: "ShopPayLoading",
        component: () => import("@/components/shop/shopPayLoading"),
    },
    {
        path: "/shop/complete",
        name: "ShopComplete",
        component: () => import("@/components/shop/shopComplete"),
        beforeEnter: (to, from, next) => {
            if(sessionStorage.getItem('orderID')){
                next();
            }
            else {
                next("/");
            }
        },
    },
	{
        path: "/shop/inspecting",
        name: "ShopInspecting",
        component: () => import("@/components/shop/shopInspecting"),
        meta: {
            title: "운영점검",
        },
    },
	{
        path: "/shop/closed",
        name: "ShopClosed",
        component: () => import("@/components/shop/shopClosed"),
        meta: {
            title: "운영종료",
        },
    },
	{
		path: "/shop/item-invalid",
		name:'ShopItemInvalid',
		component: () => import("@/components/shop/shopItemInvalid"),
	},
    {
        path: "/shop/drop",
        name: "ShopDrop",
        component: () => import("@/components/shop/shopDrop"),
    },
	{
		path:'/shop/payletter_drop',
		name:'RedirectDrop',
		component: () => import("@/components/shop/payletterDrop"),
	},
];
